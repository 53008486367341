<template>
  <b-container fluid>
    <b-row>
      <b-col md="12" class="allCardList p-0">
        <iq-card style="">
          <template v-slot:headerTitle>
            <h4 class="card-title pt-2">
              <i :class="`fa-solid fa-calendar`" aria-hidden="true" style="font-size: 21px; margin-right: 10px; color: var(--iq-primary);"></i>
              <span>{{cvCardTitle}}</span>
              <span class="btn_in_header">
                <b-button variant="primary" @click="gotoSpcmDashboard()" class="mr-2 mb-1">Counseling Dashboard</b-button>
                <b-button variant="primary" class="mb-1" @click="spcmCalendarAdd">{{cvbtnAddNew}}</b-button>
              </span>
            </h4>
            <div class="mt-2">{{userData.user_name}}</div>
          </template>
        </iq-card>

        <iq-card>
          <template v-slot:body>
            <b-row class="row w-100">
              <b-col col-xs="12" sm="6" md="4" lg="3" xl="3" class="mb-3">
                <b-form-input
                  id="search"
                  v-model="filter"
                  type="search"
                  :placeholder="cvSearchText"></b-form-input>
              </b-col>
              <!-- Counselor Name Filter -->
              <b-col col-xs="12" sm="6" md="4" lg="3" xl="3" class="mb-2" v-if="userData.user_role == 'USERROLE11111'">
                <select v-model="counsellor.user_name" class="form-control" @change="spcmCalendarList(counsellor)" v-if="counsellorObjList.length > 0" title="Counselor List">
                  <option :value="null" disabled>Choose Counselor</option>
                  <option v-for="(counsellor, index) of counsellorObjList" :key="(index+1)" :value="counsellor">
                    {{counsellor.user_name}}
                  </option>
                </select><!-- Counselor Name Filter -->
              </b-col>
              <b-col col-xs="12" sm="6" md="4" lg="4" xl="3" class="mb-2" v-if="userData.user_role == 'USERROLE11111' || userData.user_role == 'USERROLE11118'">
                <select v-model="whereFilter.is_assigned" class="form-control" @change="spcmCalendarList(counsellor)">
                  <option :value="null" disabled>Choose Assignment Status</option>
                  <option :value=1>Booked</option>
                  <option :value="0">Available</option>
                 </select>
              </b-col>
              <b-col col-xs="12" sm="6" md="12" lg="3" xl="3" class="pr-0 mb-3">
                <div class="dateRange">
                  <date-range-picker ref="picker" :show-dropdowns="true" class="w-100"  :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy' }"  v-model="whereFilter.dateRange"  @update="getspcmCalendarDateWise(counsellor)" ></date-range-picker>
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col class="container_minheight" md="12" v-if="spcmCalendarObjList && spcmCalendarObjList.length > 0">
                <b-table
                  hover
                  responsive
                  :items="spcmCalendarObjList"
                  :fields="columns"
                  :no-border-collapse="true"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  :current-page="currentPage"
                  :per-page="perPage"
                >
                <template v-slot:cell(counsellor_name)="data">
                    <p>{{data.item.counsellor_name}}</p>
                    <b-button class="btn btn actionIcon font-size-20 mr-1 mb-1 btn-secondary btn-sm" size="sm" @click="spcmCalendarEdit('MODAL', data.item)" v-if="!data.item.editable"><i class="ri-ball-pen-fill font-size-20 primary-color m-0"></i></b-button>
                    <b-button variant="btn btn actionIcon font-size-20 mr-1 mb-1 btn-secondary btn-sm" @click="showSpcmCalenderDeleteDialog(data.item)" size="sm"><i class="ri-delete-bin-line font-size-20 primary-color m-0"></i></b-button>
                </template>
                <template v-slot:cell(slot_start_ts)="data">
                    {{data.item.slot_start_ts | dateFormatDDMMYYYYHHMMA}}
                  </template>
                  <template v-slot:cell(slot_duration)="data">
                    {{data.item.slot_duration | durationToDHM}}
                  </template>
                  <template v-slot:cell(is_assigned)="data">
                    <div v-if="data.item.is_assigned == 1">
                      Booked
                    </div>
                    <div v-else>
                      Available
                    </div>
                  </template>
                </b-table>
              </b-col>
              <b-col class="container_minheight" md="12" v-else>
                No data found
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="7" md="8" class="my-1"></b-col>
              <b-col sm="5" md="4" class="my-1">
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  size="sm"
                  class="my-0"
                ></b-pagination>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>

    <b-modal
      v-model="showModelSpcmCalenderAdd"
      scrollable
      :title="cvAddModalHeader"
      size="lg"
    >
      <SpcmCalenderAdd :propOpenedInModal="true" @emitCloseSpcmCalenderAddModal="closeSpcmCalenderAddModal" @emitAddsessions="addSessions" />
      <template #modal-footer="">
        <b-button size="sm" @click="closeSpcmCalenderAddModal()">
          {{cvbtnModalCancel}}
        </b-button>
      </template>
    </b-modal>

    <b-modal
      v-model="showModelSpcmCalenderEdit"
      scrollable
      :title="cvEditModalHeader"
      size="lg"
    >
      <SpcmCalenderEdit :propOpenedInModal="true" :propSpcmCalenderObj="spcmCalendarEditObj" @emitCloseSpcmCalenderEditModal="closeSpcmCalenderEditModal" @emitEditFails="editFails" />
      <template #modal-footer="">
        <b-button size="sm" class="pull-left" @click="spcmCalendarEdit('FULLPAGE')">
          {{cvbtnModalExpand}}
        </b-button>
        <b-button size="sm" @click="closeSpcmCalenderEditModal()">
          {{cvbtnModalCancel}}
        </b-button>
      </template>
    </b-modal>

    <b-modal v-model="showModelSpcmCalenderDelete" size="lg" title="Delete" ok-title="Delete" cancel-title="Cancel">
      <p>{{cvDelMessage}}</p>
      <template #modal-footer="">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button size="sm" @click="showModelSpcmCalenderDelete = false">
          {{cvbtnModalCancel}}
        </b-button>
        <b-button size="sm" variant="primary" @click="spcmCalendarDelete()">
          {{cvbtnModalDelOk}}
        </b-button>
      </template>
    </b-modal>

    <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
      <div v-html="toastMsg"></div>
    </b-toast>
  </b-container>
</template>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style scoped lang="scss">
  .vdatetime.datetime_input{
    border: 1px solid #eeeeee;
    border-radius: 10px;
    height: 45px;
    cursor: pointer;
    padding-left: 10px;
    color: #a09e9e;
    width: 100%;
    input{
      border: 0px;
      width: 100%;
      height: 44px;
      background: transparent;
    }
  }
  .theme-primary .vdatetime-popup__header,
  .theme-primary .vdatetime-calendar__month__day--selected > span > span,
  .theme-primary .vdatetime-calendar__month__day--selected:hover > span > span {
    background: #000000;
  }

  .theme-primary .vdatetime-year-picker__item--selected,
  .theme-primary .vdatetime-time-picker__item--selected,
  .theme-primary .vdatetime-popup__actions__button {
    color: #000000;
  }
</style>

<script>
import { SpcmCalenders } from "../../../FackApi/api/SpcmCalender.js"
import SpcmCalenderAdd from "./SpcmCalenderAdd.vue"
import SpcmCalenderEdit from "./SpcmCalenderEdit"
import moment from "moment"
import ApiResponse from "../../../Utils/apiResponse.js"
import "vue-datetime/dist/vue-datetime.css"
import { socialvue } from "../../../config/pluginInit.js"
import userPermission from "../../../Utils/user_permission.js"
// import { Datetime } from "vue-datetime"
import { SpcmCounsellors } from "../../../FackApi/api/SpcmCounsellor.js"

export default {
  name: "SpcmCalenderList",
  components: {
    SpcmCalenderAdd,
    SpcmCalenderEdit
  },
  data () {
    return {
      apiName: "spcm_calendar_console_list",
      cvCardTitle: "Counselor's Calendar",
      cvbtnAddNew: "Add New",
      cvSearchText: "Type to Search",
      cvbtnModalDelOk: "Delete",
      cvDelMessage: "Are you sure?",
      cvEditModalHeader: "Edit Counselor Calender",
      cvAddModalHeader: "Add Calender",
      cvbtnModalCancel: "Cancel",
      cvbtnModalExpand: "Expand",
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "Counselor's Calendar",
      showModelSpcmCalenderAdd: false,
      showModelSpcmCalenderEdit: false,
      showModelSpcmCalenderDelete: false,
      sortBy: "name",
      sortDesc: false,
      date: "0",
      columns: null,
      spcmCalendarObjList: null,
      spcmCalendarEditObj: null,
      filter: null,
      userId: null,
      filterOn: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      delObj: null,
      vmStartDate: "",
      status: {},
      slot_start_ts: "",
      counsellor: { user_name: "null" },
      counsellorObjList: [],
      whereFilter: {
        is_assigned: "null",
        dateRange: {
          startDate: new Date(new Date().setDate(new Date().getDate() - 365)), // From Last Year
          endDate: new Date(new Date().setDate(new Date().getDate() + 1))
        }
      },
      spcmCalendarObjListCopy: []
    }
  },
  computed: {
    userData () {
      return this.$store.getters["User/userData"]
    },
    dateFilter () {
      return { ...this.$store.getters["DateFilter/selectedDateState"] }
    }
  },
  beforeMount () {
    // Auth Access for Current User Role
    if (!userPermission(this, this.userData, this.apiName)) {
      this.$router.back()
    }
  },
  async mounted () {
    socialvue.index()
    if (this.$route.params && this.userData.user_role != "USERROLE11111") {
      this.userId = this.$route.params.user_id
    }

    this.getDateFilter()
    this.spcmCalendarList()
    if (this.userData.user_role == "USERROLE11111") {
      this.setFilters()
    }

    switch (this.userData.user_role) {
      case "USERROLE11111":
        this.columns = [
          { label: "# ", key: "id", class: "text-left align-text-top", sortable: true },
          { label: "counsellor Name", key: "counsellor_name", class: "table_colume", sortable: true },
          { label: "Date", key: "slot_start_ts", class: "table_colume", sortable: true },
          { label: "slot duration", key: "slot_duration", class: "table_colume", sortable: true },
          { label: "Status", key: "is_assigned", class: "table_colume", sortable: true }
        ]
        break
      case "USERROLE11118":
        this.columns = [
          { label: "# ", key: "id", class: "text-left align-text-top", sortable: true },
          { label: "Date", key: "slot_start_ts", class: "table_colume", sortable: true },
          { label: "slot duration", key: "slot_duration", class: "table_colume", sortable: true },
          { label: "Status", key: "is_assigned", class: "table_colume", sortable: true }
        ]
    }
  },
  methods: {
    /**
     * Get daterange from LS. If found then use this as default else use the onse set in the data section of the object
    */
    async getDateFilter () {
      let storeDate = await this.dateFilter
      if (storeDate && storeDate.dateRange) {
        this.whereFilter = storeDate
      }
    },
    /**
     * Calling Function as per whereFilter
    */
    async getspcmCalendarDateWise (event) {
      this.spcmCalendarList(event)
      this.setDateFilter()
    },
    /**
     * Setting Date Range in the LS
     */
    async setDateFilter () {
      let endDate = this.whereFilter.dateRange.endDate
      this.whereFilter.dateRange.endDate = new Date(new Date(endDate).setDate(new Date(endDate).getDate() + 1))
      let whereDateFilter = { dateRange: this.whereFilter.dateRange }
      await this.$store.commit("DateFilter/addSelectedDate", whereDateFilter)
    },
    /**
     * spcmCalendarList
     */
    async spcmCalendarList (event = null) {
      try {
        if (event && event.user_name.counsellor_user_id) {
          this.whereFilter.counsellor_id = event.user_name.counsellor_user_id
        }
        else if (this.userData.user_role == "USERROLE11118") {
          this.whereFilter.counsellor_id = this.userData.user_id
        }
        else {
          this.whereFilter.counsellor_id = this.counsellor.counsellor_user_id
        }

        let spcmCalendarListResp = await SpcmCalenders.spcmCalendarConsoleList(this, this.whereFilter)
        if (spcmCalendarListResp.resp_status) {
          this.spcmCalendarObjList = spcmCalendarListResp.resp_data.data
          this.spcmCalendarObjListCopy = JSON.parse(JSON.stringify(this.spcmCalendarObjList))
          // this.spcmcalendarObjList.slot_start_ts = this.vmStartDate
          this.totalRows = spcmCalendarListResp.resp_data.count
        }
        else {
          this.spcmCalendarObjList = []
          this.toastMsg = spcmCalendarListResp.resp_msg
          this.toastVariant = "danger"
          this.showToast = true
        }
      }
      catch (err) {
        console.log("Exception occurred at spcmCalendarList() and Exception:", err.message)
      }
    },
    /**
     * editFails
     */
    editFails (spcmCalendarObj) {
      console.log("Edit fails", spcmCalendarObj)
      console.log(this.spcmCalendarObjListCopy)
      this.spcmCalendarObjList = this.spcmCalendarObjListCopy
    },
    /**
     * getFormattedDateTime
     */
    getFormattedDate (date) {
      try {
        return moment(date).format("DD-MM-YYYY")
      }
      catch (err) {
        console.error("Exception occurred at getFormattedDate() and Exception:", err.message)
      }
    },
    addSessions (sessions) {
      console.log("sessions", sessions)
      this.spcmCalendarObjList = [...this.spcmCalendarObjList, ...sessions]
      console.log(this.spcmCalendarObjList)
    },
    /**
     * spcmCalendarAdd
     */
    spcmCalendarAdd (type) {
      try {
        if (type === "FULLPAGE") {
          this.$router.push("/admin/spcmCalendar_add")
        }
        else {
          this.showModelSpcmCalenderAdd = true
        }
      }
      catch (err) {
        console.error("Exception occurred at spcmCalendarAdd() and Exception:", err.message)
      }
    },
    /**
     * spcmCalendarEdit
     */
    spcmCalendarEdit (type, item) {
      try {
        if (type === "FULLPAGE") {
          this.$router.push("../spcmCalendar_edit/" + this.spcmCalendarEditObj.spcm_calendar_id)
        }
        else {
          this.spcmCalendarEditObj = item
          this.showModelSpcmCalenderEdit = true
        }
      }
      catch (err) {
        console.error("Exception occurred at spcmCalendarEdit() and Exception:", err.message)
      }
    },
    /**
     * showSpcmCalenderDeleteDialog
     */
    showSpcmCalenderDeleteDialog (item) {
      try {
        this.delObj = item
        this.showModelSpcmCalenderDelete = true
      }
      catch (err) {
        console.error("Exception occurred at showSpcmCalenderDeleteDialog() and Exception:", err.message)
      }
    },
    /**
     * spcmCalendarDelete
     */
    async spcmCalendarDelete () {
      try {
        let spcmCalendarDelResp = await SpcmCalenders.spcmCalendarDelete(this, this.delObj.spcm_calendar_id)
        await ApiResponse.responseMessageDisplay(this, spcmCalendarDelResp)

        if (spcmCalendarDelResp && !spcmCalendarDelResp) {
          this.showModelSpcmCalenderDelete = false
          return false
        }

        let index = this.spcmCalendarObjList.indexOf(this.delObj)
        this.spcmCalendarObjList.splice(index, 1)
        this.totalRows = this.totalRows - 1
        this.showModelSpcmCalenderDelete = false
      }
      catch (err) {
        console.error("Exception occurred at spcmCalendarDelete() and Exception:", err.message)
      }
    },
    /**
     * closeSpcmCalenderAddModal
     */
    closeSpcmCalenderAddModal (spcmCalendarAddData) {
      console.log("Close Modal", spcmCalendarAddData)
      try {
        if (spcmCalendarAddData) {
          if (this.spcmCalendarObjList && this.spcmCalendarObjList.length >= 1) {
            let spcmCalendarObjLength = this.spcmCalendarObjList.length
            let lastId = this.spcmCalendarObjList[spcmCalendarObjLength - 1]["id"]
            spcmCalendarAddData.id = lastId + 1
          }
          else {
            this.spcmCalendarObjList = []
            spcmCalendarAddData.id = 11111
          }

          spcmCalendarAddData.created_on = moment()
          this.spcmCalendarObjList.unshift(spcmCalendarAddData)
          this.totalRows = this.totalRows + 1
        }

        this.showModelSpcmCalenderAdd = false
        this.spcmCalendarObjListCopy = []
      }
      catch (err) {
        console.error("Exception occurred at closespcmCalendarAddModal() and Exception:", err.message)
      }
    },
    /**
     * closeSpcmCalenderEditModal
     */
    closeSpcmCalenderEditModal () {
      try {
        this.showModelSpcmCalenderEdit = false
      }
      catch (err) {
        console.error("Exception occurred at closeSpcmCalenderEditModal() and Exception:", err.message)
      }
    },
    /**
     * gotoSpcmDashboard
     */
    gotoSpcmDashboard () {
      this.$router.push(`/spcm_counseling_dashboard`)
    },
    /**
     * setFilters
     */
    async setFilters () {
      const counselorsListResp = await SpcmCounsellors.spcmCounsellorList()
      if (counselorsListResp.resp_status) {
        this.counsellorObjList = counselorsListResp.resp_data.data
      }
    }
  }
}
</script>
