<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12">
        <iq-card>
          <template v-slot:headerTitle v-if="!propOpenedInModal">
            <h4 class="card-title">{{cvCardTitle}}</h4>
          </template>
          <template v-slot:body>
            <p v-show="!propOpenedInModal">{{cvCardSubHeader}}</p>
            <form action="#" v-if="vmSpcmCalenderFormData && Object.keys(vmSpcmCalenderFormData).length > 0">
              <div class="form-row">

  <div class="col-md-12 mb-3">
    <label for="validationcounsellor_id">
      {{cvCounsellorIdLabel}}</label
    >
    <input
      v-model="vmSpcmCalenderFormData.counsellor_id"
      type="text"
      class="form-control"
      required
    />
</div>
<div class="col-md-6 mb-3">
    <label for="validationslot_start_ts">
      {{cvDateLabel}}</label
    >
                     <datetime
                        type="date"
                        v-model="convertedDateTime"
                        :value="convertedDateTime"
                        input-class="form-control"
                        :format="{ year: 'numeric', month: 'long', day: 'numeric'}"
                        :phrases="{ok: 'Continue', cancel: 'Exit'}"
                        :hour-step="1"
                        :minute-step="5"
                        :week-start="7"
                        use12-hour
                        auto
                        :min-datetime="vmStartDate ? vmStartDate : minStartDate"></datetime>

</div>
<div class="col-md-6 mb-3">
    <label for="validationslot_start_ts">
      Time</label
    >
                     <datetime
                        type="time"
                        :value="convertedDateTime2"
                        v-model="convertedDateTime2"
                        input-class="form-control"
                        :format="{hour: 'numeric', minute: '2-digit'}"
                        :phrases="{ok: 'Continue', cancel: 'Exit'}"
                        :hour-step="1"
                        :minute-step="5"
                        :week-start="7"
                        use12-hour
                        auto
                        :min-datetime="vmStartDate ? vmStartDate : minStartDate"></datetime>

</div>
 <div class="col-md-12 mb-3">
    <label for="validationslot_duration">
      {{cvSlotDurationLabel}} (in secs)</label
    >
    <input
      v-model="vmSpcmCalenderFormData.slot_duration"
      type="text"
      class="form-control"
      required
    />
</div>
              </div>
              <div class="form-group">
                <button v-if="cvLoadingStatus" type="button" class="btn btn-primary" :disabled="cvLoadingStatus">
                   <b-spinner label="Spinning"></b-spinner>
                </button>
                <button type="button" class="btn btn-primary" @click="spcmCalendarEdit()">{{cvSubmitBtn}}</button>
              </div>
            </form>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
      <div v-html="toastMsg"></div>
    </b-toast>
  </b-container>
</template>

<script>
import { SpcmCalenders } from "../../../FackApi/api/SpcmCalender"
import ApiResponse from "../../../Utils/apiResponse.js"
import { Datetime } from "vue-datetime"
import moment from "moment"
export default {
  name: "SpcmCalenderEdit",
  props: {
    propOpenedInModal: {
      type: Boolean,
      default: false
    },
    propSpcmCalenderObj: {
      type: Object,
      default: function () {
        return {
          "counsellor_id": "",
          "slot_start_ts": "",
          "slot_duration": ""
        }
      }
    }
  },
  data () {
    return {
      cvCardTitle: "Edit Counsellor's Calendar",
      cvCardSubHeader: "Edit Counsellor's Calendar",
      cvSubmitBtn: "Edit",
      cvCounsellorIdLabel: "Counsellor Id",
      cvSlotStartTsLabel: "Time",
      cvDateLabel: "Date",
      cvSlotDurationLabel: "Duration",
      showToast: false,
      cvLoadingStatus: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "Edit Response",
      vmSpcmCalenderFormData: {},
      vmStartDate: "",
      minStartDate: "",
      convertedDateTime2: "",
      convertedDateTime: "",
      propSpcmCalendarObjCopy: {}
    }
  },
  components: {
    Datetime
  },
  mounted () {
    this.propSpcmCalendarObjCopy = { ...this.propSpcmCalenderObj }
    this.spcmCalendarView()
  },
  methods: {
    /**
     * validate
     */
    validate () {
      try {
        for (let index in this.vmSpcmCalenderFormData) {
          if (!this.vmSpcmCalenderFormData[index]) {
            this.toastMsg = "Missing " + index.split("_").join(" ")
            this.toastVariant = "danger"
            this.showToast = true
            return false
          }
        }
        return true
      }
      catch (err) {
        console.error("Exception occurred at validate() and Exception:", err.message)
        return null
      }
    },
    /**
     * spcmCalendarView
     */
    async spcmCalendarView () {
      if (this.propSpcmCalenderObj.slot_start_ts) {
        const date = new Date(this.propSpcmCalenderObj.slot_start_ts * 1000)
        this.convertedDateTime = moment(date.setDate(date.getDate() + 1)).format("YYYY-MM-DD") // Convert Unix timestamp to milliseconds
        this.convertedDateTime2 = moment(new Date(this.propSpcmCalenderObj.slot_start_ts * 1000)).format("HH:mm") // Convert Unix timestamp to milliseconds
      }
      else {
        this.convertedDateTime = null // Convert Unix timestamp to milliseconds
        this.convertedDateTime2 = null // Convert Unix timestamp to milliseconds
      }
      try {
        if (this.propOpenedInModal) {
          this.vmSpcmCalenderFormData = this.propSpcmCalenderObj
        }
        else {
          let spcmCalendarId = this.$route.params.spcm_calendar_id
          let spcmCalendarViewResp = await SpcmCalenders.spcmCalendarView(this, spcmCalendarId)
          const spcmCalendarObj = spcmCalendarViewResp.resp_data.data
          if (spcmCalendarObj.slot_start_ts) {
            const date = new Date(spcmCalendarObj.slot_start_ts * 1000)
            this.convertedDateTime = moment(date.setDate(date.getDate() + 1)).format("YYYY-MM-DD") // Convert Unix timestamp to milliseconds
            this.convertedDateTime2 = moment(new Date(spcmCalendarObj.slot_start_ts * 1000)).format("HH:mm") // Convert Unix timestamp to milliseconds
          }
          else {
            this.convertedDateTime = null // Convert Unix timestamp to milliseconds
            this.convertedDateTime2 = null // Convert Unix timestamp to milliseconds
          }
          if (spcmCalendarViewResp && spcmCalendarViewResp.resp_status) {
            this.vmSpcmCalenderFormData = spcmCalendarViewResp.resp_data.data
          }
        }
      }
      catch (err) {
        console.error("Exception occurred at spcmCalendarView() and Exception:", err.message)
      }
    },
    /**
     * spcmCalendarEdit
     */
    async spcmCalendarEdit () {
      try {
        const date = new Date(this.convertedDateTime)
        let formattedDate = date.toLocaleDateString("en-US", { year: "numeric", month: "2-digit", day: "2-digit" })
        formattedDate = formattedDate.split("/").map(Number)
        const date2 = new Date(this.convertedDateTime2)
        const hours = (date2.getHours())
        const minutes = (date2.getMinutes())

        this.vmSpcmCalenderFormData.slot_start_ts = moment(new Date(formattedDate[2], formattedDate[0] - 1, formattedDate[1], hours, minutes)).utc().unix()
        this.cvLoadingStatus = true
        let spcmCalendarAddResp = await SpcmCalenders.spcmCalendarEdit(this, this.vmSpcmCalenderFormData)
        await ApiResponse.responseMessageDisplay(this, spcmCalendarAddResp)

        if (spcmCalendarAddResp && !spcmCalendarAddResp.resp_status) {
          this.$emit("emitEditFails", this.propSpcmCalendarObjCopy)
          return false
        }

        //  Emit if opened in Modal
        if (this.propOpenedInModal) {
          this.$emit("emitCloseSpcmCalenderEditModal", this.vmSpcmCalenderFormData)
        }
      }
      catch (err) {
        console.error("Exception occurred at spcmCalendarEdit() and Exception:", err.message)
      }
      finally {
        this.cvLoadingStatus = false
      }
    }
  }
}
</script>
